import React from 'react'
import './styles.css'

function Successmessage() {
  return (
    <div>
      <h3 className='success'>Success! Thanks for Submitting</h3>
    </div>
  )
}

export default Successmessage
