import React, { useReducer, useState } from 'react';
import './styles.css';
import Successmessage from './Successmessage';

function Login() {
  const [displaySuccess, setDisplay] = useState(false);
  const [userdetail , setData] = useState({name:"" , email : "" , password : ""})
  const [tempvalues , settempvalues] = useState({})

  function handleSubmit(event) {
    settempvalues({...userdetail})
    event.preventDefault(); // Prevents page refresh on form submit
    setDisplay(true);
    // setData("")
  }

  function changedetails (event) {
    const { name, value } = event.target
    setData((previousState) => ({...previousState , [name] : value}))
  }

  return (
    <div className="main">
      <div className="login-container">

        {displaySuccess && tempvalues.name && tempvalues.email && tempvalues.password ? <Successmessage /> : null}

        <h1>Login Form</h1>
        <form onSubmit={handleSubmit}>
          <input 
          name='name'
          value={userdetail.name}
          onChange={changedetails}
          placeholder="Name" 
          type="text" 
          />
          {!userdetail.name && displaySuccess ? <p>Please Enter a Name</p> : null }
          
          <input 
          name='email'
          value={userdetail.email}
          onChange={changedetails}
          placeholder="Email" 
          type="email" 
          />
          {!userdetail.email && displaySuccess ? <p>Please Enter a Email</p> : null }
          
          <input 
          name='password'
          value={userdetail.password}
          onChange={changedetails}
          placeholder="Password" 
          type="password" 
          />
          {!userdetail.password && displaySuccess ? <p>Please Enter a password</p> : null }
          
          <button className="submitButton" type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
